import axios from "axios";
import { serverConfig } from "@/config/serverConfig";
import jwtService from "@/services/jwt.service";

export async function store(file, options = {}) {
  const response = await axios.post(options.signedStorageUrl 
      ? options.signedStorageUrl 
      : `${serverConfig.doyousignUrl}/vapor/signed-storage-url`,
    {
      'bucket': options.bucket || '',
      'content_type': options.contentType || file.type,
      'expires': options.expires || '',
      'visibility': options.visibility || '',
      ...options.data
    }, {
      baseURL: options.baseURL || null,
      headers: options.headers || {},
      ...options.options
    }
  );
  let headers = response.data.headers;

  if ('Host' in headers) {
    delete headers.Host;
  }

  if (typeof options.progress === 'undefined') {
    options.progress = () => {};
  }

  delete axios.defaults.headers.common['Authorization'];

  const cancelToken = options.cancelToken || '';
  await axios.put(response.data.url, file, {
    cancelToken: cancelToken,
    headers: headers,
    withCredentials: false,
    onUploadProgress: progressEvent => {
      options.progress(progressEvent.loaded / progressEvent.total);
    }
  });
  response.data.extension = file.name.split('.').pop();

  axios.defaults.headers.common['Authorization'] = `Bearer ${jwtService.getToken().token}`;
  return response.data;
}